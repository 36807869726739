import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container, Content, mobileThresholdPixels } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';


const StyledContainer = styled(Container)`
  padding-top: 70px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 43px;
  }
`;

class LandingPageAds extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { pageContext: {
      slug, city, locality, fabric, piece, backgroundImage, mobileBackgroundImage,
      couturierBlock, customerFeedbacks, faqQuestions, brands, prefilledSteps: prefilledStepsProps,
    } } = this.props;
    const title = fabric === 'curtains'
      ? 'Faites venir un couturier chez vous pour retoucher vos rideaux et voilages'
      // eslint-disable-next-line max-len
      : `Nos couturiers se déplacent chez vous, ${locality ? `à ${locality}` : ''} pour vos retouches ${piece ? `de ${piece}` : ''}`;
    let prefilledSteps = fabric === 'curtains' ?
      { selectedCategory: 'deco', selectedPiece: 'ride', selectedTypeOfWork: 'alteration' } :
      { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' };
    if (prefilledStepsProps) prefilledSteps = { ...prefilledSteps, ...prefilledStepsProps };
    return (
      <Layout routeSlug={slug}>
        <StyledContainer>
          <Content full>
            <MenuBar showGiftBanner={false} />
            <Header
              title={title}
              hideNote
              smallTitle
              titleLeft
              curtainsPhoto={fabric === 'curtains'}
              curtainsNote={fabric === 'curtains'}
              curtainsVersion={fabric === 'curtains'}
              prefilledSteps={prefilledSteps}
              backgroundImage={backgroundImage?.publicURL}
              mobileBackgroundImage={mobileBackgroundImage?.publicURL}
            />
            <BenefitsBanner />
            <ACouturierAtHome
              curtainsVersion={fabric === 'curtains'}
              prefilledSteps={prefilledSteps}
              couturierBlock={couturierBlock}
            />
            <FeedbacksBannerAds curtainsVersion={fabric === 'curtains'} customerFeedbacks={customerFeedbacks} />
            <FAQAds
              faqQuestions={faqQuestions}
              curtainsVersion={fabric === 'curtains'}
              city={city}
              prefilledSteps={prefilledSteps}
            />
            <PartnersPager curtainsVersion={fabric === 'curtains'} brands={brands} />
            <Footer />
          </Content>
        </StyledContainer>
      </Layout>
    );
  }
}

LandingPageAds.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    city: PropTypes.string,
    locality: PropTypes.string,
    fabric: PropTypes.string,
    piece: PropTypes.string,
    backgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }).isRequired,
    mobileBackgroundImage: PropTypes.shape({
      publicURL: PropTypes.string,
    }).isRequired,
    couturierBlock: PropTypes.shape({
      text1: PropTypes.string,
      text2: PropTypes.string,
      text2HasPersonalPronoun: PropTypes.bool,
      imageSrc: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
    customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
      photo: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    })),
    faqQuestions: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })),
    brands: PropTypes.arrayOf(PropTypes.shape({
      img: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      index: PropTypes.number,
    })),
    prefilledSteps: PropTypes.shape({
      selectedCloth: PropTypes.string,
      selectedFabric: PropTypes.string,
    }),
  }).isRequired,
};

export default LandingPageAds;

